import starGrey from "../../assets/starGrey.png";
import starGreen from "../../assets/starGreen.png";
import review1 from "../../assets/review1.jpg";
import review2 from "../../assets/review2.jpg";
import review3 from "../../assets/review3.jpg";
import review4 from "../../assets/review4.jpg";
import review5 from "../../assets/review5.jpg";
import review6 from "../../assets/review6.jpg";
import like from "../../assets/like.svg";
import user from "../../assets/user.png";
import { useState } from "react";
import user1 from "../../assets/user1.png";
import user2 from "../../assets/user2.png";

export const Reviews = () => {
  const [showReview, setShowReview] = useState(false);
  const [selectOpen, setSelectOpen] = useState(false);
  return (
    <>
      <p className="reviews__title">Рейтинг и отзывы</p>
      <div className="reviews__wrapper">
        <div>
          <p className="reviews__score">4,8</p>
          <div className="star__wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="star__fill"
            >
              <path d="M0 0h24v24H0z" fill="none"></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
              <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="star__fill"
            >
              <path d="M0 0h24v24H0z" fill="none"></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
              <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="star__fill"
            >
              <path d="M0 0h24v24H0z" fill="none"></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
              <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="star__fill"
            >
              <path d="M0 0h24v24H0z" fill="none"></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
              <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="star__fill"
            >
              <path d="M0 0h24v24H0z" fill="none"></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
              <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
            </svg>
          </div>
          <p className="user__wrapper">
            Общий
            <img src={user} alt="user icon" width={16} height={16} />
          </p>
          <p className="reviews__number">5,7 тис. відгуки</p>
        </div>
        <div>
          <ul className="lines__list">
            <li className="lines__item">
              <p className="numbers">5</p>
              <div className="first__line desktop_one">
                <div className="first__line--green"></div>
              </div>
            </li>
            <li className="lines__item">
              <p className="numbers">4</p>
              <div className="first__line desktop_two">
                <div className="second__line--green"></div>
              </div>
            </li>

            <li className="lines__item">
              <p className="numbers">3</p>
              <div className="first__line desktop_three">
                <div className="third__line--green"></div>
              </div>
            </li>

            <li className="lines__item">
              <p className="numbers">2</p>
              <div className="first__line desktop_four">
                {/* <div className="fourth__line--green"></div> */}
              </div>
            </li>

            <li className="lines__item">
              <p className="numbers">1</p>
              <div className="first__line desktop_five">
                <div className="fourth__line--green"></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="review__one">
        <div className="review__one--thumb">
          <div className="review__one--wrapper">
            <img src={user1} alt="user1" className="review__logo--one" />
            <p className="review__one--name">Игорь</p>
          </div>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="10" cy="5" r="1.5" fill="#5F6367" />
            <circle cx="10" cy="10" r="1.5" fill="#5F6367" />
            <circle cx="10" cy="15" r="1.5" fill="#5F6367" />
          </svg>
        </div>
        <div className="review__two--wrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="star__fill"
            style={{ width: "12px" }}
          >
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="star__fill"
            style={{ width: "12px" }}
          >
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="star__fill"
            style={{ width: "12px" }}
          >
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="star__fill"
            style={{ width: "12px" }}
          >
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="star__fill"
            style={{ width: "12px", marginRight: "4px" }}
          >
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
          </svg>
          26 квітня 2024 р.
        </div>
        <p className="review__three--text">
          Казино реально дает, закинул сотку и прикурил 5300 на зевсе
        </p>
        <p className="review__four--text">
          3 користувачі вважають цей відгук корисним
        </p>
        <div className="review__five--wrapper">
          <p className="review__four--text">Ви вважаєте цей відгук корисним?</p>
          <div className="review__answers--wrapper">
            <div className="review__answers" style={{ width: "56px" }}>
              Так
            </div>
            <div className="review__answers" style={{ width: "47px" }}>
              Ні
            </div>
          </div>
        </div>
      </div>
      <div className="review__one">
        <div className="review__one--thumb">
          <div className="review__one--wrapper">
            <img src={user2} alt="user1" className="review__logo--one" />
            <p className="review__one--name">Валерия</p>
          </div>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="10" cy="5" r="1.5" fill="#5F6367" />
            <circle cx="10" cy="10" r="1.5" fill="#5F6367" />
            <circle cx="10" cy="15" r="1.5" fill="#5F6367" />
          </svg>
        </div>
        <div className="review__two--wrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="star__fill"
            style={{ width: "12px" }}
          >
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="star__fill"
            style={{ width: "12px" }}
          >
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="star__fill"
            style={{ width: "12px" }}
          >
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="star__fill"
            style={{ width: "12px" }}
          >
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="star__fill"
            style={{ width: "12px", marginRight: "4px" }}
          >
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
          </svg>
          26 квітня 2024 р.
        </div>
        <p className="review__three--text">
          Сидела дома, готовила ужин, интересно было попробовать. Смогла
          выиграть за час 3300 грн
        </p>
        <p className="review__four--text">
          7 користувачі вважають цей відгук корисним
        </p>
        <div className="review__five--wrapper">
          <p className="review__four--text">Ви вважаєте цей відгук корисним?</p>
          <div className="review__answers--wrapper">
            <div className="review__answers" style={{ width: "56px" }}>
              Так
            </div>
            <div className="review__answers" style={{ width: "47px" }}>
              Ні
            </div>
          </div>
        </div>
      </div>
      <div className="review__one">
        <div className="review__one--thumb">
          <div className="review__one--wrapper">
            <div
              className="review__logo--one"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "rgb(126, 55, 148)",
                color: "#fff",
                fontSize: "11px",
              }}
            >
              И
            </div>
            <p className="review__one--name">Игорь</p>
          </div>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="10" cy="5" r="1.5" fill="#5F6367" />
            <circle cx="10" cy="10" r="1.5" fill="#5F6367" />
            <circle cx="10" cy="15" r="1.5" fill="#5F6367" />
          </svg>
        </div>
        <div className="review__two--wrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="star__fill"
            style={{ width: "12px" }}
          >
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="star__fill"
            style={{ width: "12px" }}
          >
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="star__fill"
            style={{ width: "12px" }}
          >
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="star__fill"
            style={{ width: "12px" }}
          >
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="star__fill"
            style={{ width: "12px", marginRight: "4px" }}
          >
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
          </svg>
          26 квітня 2024 р.
        </div>
        <p className="review__three--text">
          Надоели эти казино, постоянно вижу их рекламу, пробую и проигрываю, а
          тут пошло все красиво, наверное, потому что казино новое
        </p>
        <p className="review__four--text">
          19 користувачі вважають цей відгук корисним
        </p>
        <div className="review__five--wrapper">
          <p className="review__four--text">Ви вважаєте цей відгук корисним?</p>
          <div className="review__answers--wrapper">
            <div className="review__answers" style={{ width: "56px" }}>
              Так
            </div>
            <div className="review__answers" style={{ width: "47px" }}>
              Ні
            </div>
          </div>
        </div>
      </div>
      <p className="review__six--text">Переглянути всі відгуки</p>
      <p className="main__about">Що нового</p>
      <p className="review__seven--text">
        Додали швидшу верифікацію <br />
        Доступний новий бездепозитний бонус 200 грн
      </p>
      <div
        className="main__info2--mid"
        style={{ marginTop: "25px", marginBottom: "40px" }}
      >
        <p className="main__about">Контактні дані розробника</p>
        {selectOpen ? (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              setSelectOpen(false);
            }}
          >
            <g clipPath="url(#clip0_1176_4652)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.9396 7.9399C11.2209 7.659 11.6021 7.50122 11.9996 7.50122C12.3971 7.50122 12.7784 7.659 13.0596 7.9399L18.7176 13.5959C18.8569 13.7352 18.9674 13.9006 19.0427 14.0827C19.1181 14.2647 19.1568 14.4598 19.1568 14.6568C19.1567 14.8538 19.1179 15.0488 19.0424 15.2308C18.967 15.4128 18.8565 15.5781 18.7171 15.7174C18.5778 15.8567 18.4124 15.9671 18.2304 16.0425C18.0484 16.1178 17.8533 16.1566 17.6563 16.1565C17.4593 16.1565 17.2642 16.1176 17.0822 16.0422C16.9002 15.9668 16.7349 15.8562 16.5956 15.7169L11.9996 11.1219L7.40364 15.7179C7.26533 15.8612 7.09987 15.9756 6.9169 16.0543C6.73393 16.133 6.53712 16.1744 6.33796 16.1763C6.13879 16.1781 5.94125 16.1402 5.75687 16.0649C5.57249 15.9896 5.40496 15.8783 5.26406 15.7375C5.12315 15.5967 5.0117 15.4293 4.93619 15.245C4.86068 15.0607 4.82263 14.8632 4.82427 14.664C4.82591 14.4648 4.86719 14.268 4.94572 14.085C5.02424 13.9019 5.13844 13.7363 5.28164 13.5979L10.9396 7.9399Z"
                fill="#5F6367"
              />
            </g>
            <defs>
              <clipPath id="clip0_1176_4652">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        ) : (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              setSelectOpen(true);
            }}
          >
            <g clipPath="url(#clip0_1176_4648)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.0609 16.0599C12.7796 16.3408 12.3984 16.4986 12.0009 16.4986C11.6034 16.4986 11.2221 16.3408 10.9409 16.0599L5.28288 10.4039C5.00161 10.1225 4.84366 9.74089 4.84375 9.34304C4.84384 8.94518 5.00198 8.56365 5.28338 8.28239C5.56477 8.00113 5.94637 7.84317 6.34423 7.84326C6.74209 7.84336 7.12361 8.00149 7.40488 8.28289L12.0009 12.8789L16.5969 8.28289C16.8797 8.00952 17.2585 7.85814 17.6518 7.86137C18.0451 7.8646 18.4214 8.02218 18.6996 8.30016C18.9779 8.57815 19.1358 8.95429 19.1394 9.34759C19.143 9.74088 18.992 10.1199 18.7189 10.4029L13.0619 16.0609L13.0609 16.0599Z"
                fill="#5F6367"
              />
            </g>
            <defs>
              <clipPath id="clip0_1176_4648">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        )}
      </div>
      <div
        className="review__bottom--wrapper"
        style={{ display: selectOpen ? "flex" : "none" }}
      >
        <div className="review__bottom--thumb">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.6 9H20.4M3.6 15H20.4M3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 9.61305 20.0518 7.32387 18.364 5.63604C16.6761 3.94821 14.3869 3 12 3C9.61305 3 7.32387 3.94821 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12Z"
              stroke="#5F6367"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.4997 3C9.81501 5.69961 8.92188 8.81787 8.92188 12C8.92188 15.1821 9.81501 18.3004 11.4997 21M12.4997 3C14.1843 5.69961 15.0775 8.81787 15.0775 12C15.0775 15.1821 14.1843 18.3004 12.4997 21"
              stroke="#5F6367"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div>
            <p className="review__bottom--title">Веб-сайт</p>
            <p className="review__bottom--text">777.ua</p>
          </div>
        </div>
        <div className="review__bottom--thumb">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 5.625C11.1842 5.625 10.3866 5.86693 9.70827 6.32019C9.02992 6.77345 8.50121 7.41768 8.189 8.17143C7.87679 8.92518 7.7951 9.75458 7.95426 10.5547C8.11343 11.3549 8.50629 12.0899 9.08318 12.6668C9.66008 13.2437 10.3951 13.6366 11.1953 13.7957C11.9954 13.9549 12.8248 13.8732 13.5786 13.561C14.3323 13.2488 14.9766 12.7201 15.4298 12.0417C15.8831 11.3634 16.125 10.5658 16.125 9.75C16.1238 8.65636 15.6888 7.60787 14.9154 6.83455C14.1421 6.06124 13.0936 5.62624 12 5.625ZM12 11.625C11.6292 11.625 11.2666 11.515 10.9583 11.309C10.65 11.103 10.4096 10.8101 10.2677 10.4675C10.1258 10.1249 10.0887 9.74792 10.161 9.3842C10.2334 9.02049 10.412 8.6864 10.6742 8.42417C10.9364 8.16195 11.2705 7.98337 11.6342 7.91103C11.9979 7.83868 12.3749 7.87581 12.7175 8.01773C13.0601 8.15964 13.353 8.39996 13.559 8.70831C13.765 9.01665 13.875 9.37916 13.875 9.75C13.875 10.2473 13.6775 10.7242 13.3258 11.0758C12.9742 11.4275 12.4973 11.625 12 11.625ZM12 1.125C9.71327 1.12748 7.52091 2.03698 5.90394 3.65394C4.28698 5.27091 3.37748 7.46327 3.375 9.75C3.375 17.0025 11.0288 22.4437 11.355 22.6716C11.5441 22.8039 11.7692 22.8748 12 22.8748C12.2308 22.8748 12.4559 22.8039 12.645 22.6716C14.0946 21.6032 15.4209 20.3769 16.5994 19.0153C19.2328 15.9909 20.625 12.7847 20.625 9.75C20.6225 7.46327 19.713 5.27091 18.0961 3.65394C16.4791 2.03698 14.2867 1.12748 12 1.125ZM14.9344 17.5041C14.0405 18.5308 13.0586 19.4776 12 20.3334C10.9414 19.4776 9.9595 18.5308 9.06562 17.5041C7.5 15.6909 5.625 12.8728 5.625 9.75C5.625 8.05924 6.29665 6.43774 7.49219 5.24219C8.68774 4.04665 10.3092 3.375 12 3.375C13.6908 3.375 15.3123 4.04665 16.5078 5.24219C17.7034 6.43774 18.375 8.05924 18.375 9.75C18.375 12.8728 16.5 15.6909 14.9344 17.5041Z"
              fill="#5F6367"
            />
          </svg>
          <div>
            <p className="review__bottom--title">Електронна адреса</p>
            <p className="review__bottom--text">mail@777.ua</p>
          </div>
        </div>
        <div className="review__bottom--thumb">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 5.625C11.1842 5.625 10.3866 5.86693 9.70827 6.32019C9.02992 6.77345 8.50121 7.41768 8.189 8.17143C7.87679 8.92518 7.7951 9.75458 7.95426 10.5547C8.11343 11.3549 8.50629 12.0899 9.08318 12.6668C9.66008 13.2437 10.3951 13.6366 11.1953 13.7957C11.9954 13.9549 12.8248 13.8732 13.5786 13.561C14.3323 13.2488 14.9766 12.7201 15.4298 12.0417C15.8831 11.3634 16.125 10.5658 16.125 9.75C16.1238 8.65636 15.6888 7.60787 14.9154 6.83455C14.1421 6.06124 13.0936 5.62624 12 5.625ZM12 11.625C11.6292 11.625 11.2666 11.515 10.9583 11.309C10.65 11.103 10.4096 10.8101 10.2677 10.4675C10.1258 10.1249 10.0887 9.74792 10.161 9.3842C10.2334 9.02049 10.412 8.6864 10.6742 8.42417C10.9364 8.16195 11.2705 7.98337 11.6342 7.91103C11.9979 7.83868 12.3749 7.87581 12.7175 8.01773C13.0601 8.15964 13.353 8.39996 13.559 8.70831C13.765 9.01665 13.875 9.37916 13.875 9.75C13.875 10.2473 13.6775 10.7242 13.3258 11.0758C12.9742 11.4275 12.4973 11.625 12 11.625ZM12 1.125C9.71327 1.12748 7.52091 2.03698 5.90394 3.65394C4.28698 5.27091 3.37748 7.46327 3.375 9.75C3.375 17.0025 11.0288 22.4437 11.355 22.6716C11.5441 22.8039 11.7692 22.8748 12 22.8748C12.2308 22.8748 12.4559 22.8039 12.645 22.6716C14.0946 21.6032 15.4209 20.3769 16.5994 19.0153C19.2328 15.9909 20.625 12.7847 20.625 9.75C20.6225 7.46327 19.713 5.27091 18.0961 3.65394C16.4791 2.03698 14.2867 1.12748 12 1.125ZM14.9344 17.5041C14.0405 18.5308 13.0586 19.4776 12 20.3334C10.9414 19.4776 9.9595 18.5308 9.06562 17.5041C7.5 15.6909 5.625 12.8728 5.625 9.75C5.625 8.05924 6.29665 6.43774 7.49219 5.24219C8.68774 4.04665 10.3092 3.375 12 3.375C13.6908 3.375 15.3123 4.04665 16.5078 5.24219C17.7034 6.43774 18.375 8.05924 18.375 9.75C18.375 12.8728 16.5 15.6909 14.9344 17.5041Z"
              fill="#5F6367"
            />
          </svg>
          <div>
            <p className="review__bottom--title">Адреса</p>
            <p className="review__bottom--text">
              Київ, вулиця Івана Мар'яненка, 11/12, офіс 32
            </p>
          </div>
        </div>
      </div>
      {/* <div className="item__wrapper">
        <img
          src={review1}
          alt="logo"
          width={48}
          height={48}
          className="item__logo"
        />
        <div>
          <div className="item__flex">
            <div>
              <div className="item__thumb">
                <p className="item__title">Макс Самсон</p>
                <div className="images__wrapper">
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                </div>
              </div>
              <p className="item__date">15.04.2024</p>
            </div> */}
      {/* <div className="item__likes">
              <img src={like} alt="" />
              <p className="item__like">0</p>
            </div> */}
      {/* </div>
          <p className="item__text">
            Насыпало 73800 тенге за 5 дней игры очень круто! Спасибо нашему
            президенту и kaspi банку за офигенное казино!
          </p>
          <div className="review__box">
            <div className="triangle"></div>
            <p className="review__title">Kaspi Казино</p>
            <p className="review__description">
              Максим Самсон, спасибо за отзыв! Нам очень важно, чтобы наши
              клиенты получали удовольствие от нашего проекта. Наша команда
              делает всё что бы вы получили максимальное наслаждение пользуясь
              нашим продуктом!
            </p>
          </div>
        </div>
      </div>
      <div className="item__wrapper">
        <img
          src={review2}
          alt="logo"
          width={48}
          height={48}
          className="item__logo"
        />
        <div>
          <div className="item__flex">
            <div>
              <div className="item__thumb">
                <p className="item__title">Сережа Курседов</p>
                <div className="images__wrapper">
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                </div>
              </div>
              <p className="item__date">13.04.2024</p>
            </div> */}
      {/* <div className="item__likes">
              <img src={like} alt="" />
              <p className="item__like">0</p>
            </div> */}
      {/* </div>
          <p className="item__text">
            У меня нет слов. С 500 тенге поднял 7000 за пару часов ;)) Давно мне
            так не фартило. Лучшее казино в которое я играл! Спасибо Kaspi и
            нашему президенту за возможность развлечься и заработать!
          </p>
          <div className="review__box">
            <div className="triangle"></div>
            <p className="review__title">Kaspi Казино</p>
            <p className="review__description">
              Сергей Курседов, благодарим вас за положительный отзыв. Стараемся
              удовлетворять наших клиентов!
            </p>
          </div>
        </div>
      </div>
      <div className="item__wrapper">
        <img
          src={review3}
          alt="logo"
          width={48}
          height={48}
          className="item__logo"
        />
        <div>
          <div className="item__flex">
            <div>
              <div className="item__thumb">
                <p className="item__title">Кирилл Апанасенко</p>
                <div className="images__wrapper">
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                </div>
              </div>
              <p className="item__date">12.04.2024</p>
            </div> */}
      {/* <div className="item__likes">
              <img src={like} alt="" />
              <p className="item__like">0</p>
            </div> */}
      {/* </div>
          <p className="item__text">
            Обычно я не верю в эти все казино! Но это приложение от Kaspi и
            нашего президента - это просто нечто! Закинул по приколу 300 тенге и
            поднял 3000 за 15 минут. Буду дальше играть и рубить бабло!
          </p>
          <div className="review__box">
            <div className="triangle"></div>
            <p className="review__title">Kaspi Казино</p>
            <p className="review__description">
              Кирилл Апанасенко, спасибо за приятный отзыв! Играйте и
              наслаждайтесь!
            </p>
          </div>
        </div>
      </div>
      {showReview && (
        <>
          <div className="item__wrapper">
            <img
              src={review4}
              alt="logo"
              width={48}
              height={48}
              className="item__logo"
            />
            <div>
              <div className="item__flex">
                <div>
                  <div className="item__thumb">
                    <p className="item__title">Влад Миронов</p>
                    <div className="images__wrapper">
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                    </div>
                  </div>
                  <p className="item__date">10.04.2024</p>
                </div> */}
      {/* <div className="item__likes">
                <img src={like} alt="" />
                <p className="item__like">0</p>
              </div> */}
      {/* </div>
              <p className="item__text">
                Не ожидал, что Kaspi Казино и Касым-Жомарт Токаев сделают такой
                крутой проект! Респект и уважение нашему правительству! Самое
                щедрое и дающее казино в моей жизни!
              </p>
              <div className="review__box">
                <div className="triangle"></div>
                <p className="review__title">Kaspi Казино</p>
                <p className="review__description">
                  Влади Миронов, благодарим за ваш отзыв. Наш проект был создан
                  исключительно для того, чтобы дарить людям эмоции. Будем
                  стараться радовать вас почаще! Удачи вам!
                </p>
              </div>
            </div>
          </div> */}
      {/* <div className="item__wrapper">
            <img
              src={review5}
              alt="logo"
              width={48}
              height={48}
              className="item__logo"
            />
            <div>
              <div className="item__flex">
                <div>
                  <div className="item__thumb">
                    <p className="item__title">Максим Ефремов</p>
                    <div className="images__wrapper">
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                    </div>
                  </div>
                  <p className="item__date">08.04.2024</p>
                </div> */}
      {/* <div className="item__likes">
                  <img src={like} alt="" />
                  <p className="item__like">0</p>
                </div> */}
      {/* </div>
              <p className="item__text">
                Насыпало за 5 дней игры очень круто! Спасибо за офигенное
                казино!
              </p>
              <div className="review__box">
                <div className="triangle"></div>
                <p className="review__title">Kaspi Казино</p>
                <p className="review__description">
                  Максим Ефремов, спасибо за отзыв! Нам очень важно, чтобы наши
                  клиенты получали удовольствие от нашего проекта.
                </p>
              </div>
            </div>
          </div>
          <div className="item__wrapper">
            <img
              src={review6}
              alt="logo"
              width={48}
              height={48}
              className="item__logo"
            />
            <div>
              <div className="item__flex">
                <div>
                  <div className="item__thumb">
                    <p className="item__title">Олег Мартынов</p>
                    <div className="images__wrapper">
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                    </div>
                  </div>
                  <p className="item__date">05.04.2024</p>
                </div> */}
      {/* <div className="item__likes">
                  <img src={like} alt="" />
                  <p className="item__like">0</p>
                </div> */}
      {/* </div>
              <p className="item__text">
                У меня нет слов. С 500 рублей поднял 7000 за пару часов ;))
                Давно мне так не фартило. Лучшее казино в которое я играл!
              </p>
              <div className="review__box">
                <div className="triangle"></div>
                <p className="review__title">Kaspi Казино</p>
                <p className="review__description">
                  Олег Мартынов, благодарим вас за положительный отзыв.
                  Стараемся удовлетворять наших клиентов!
                </p>
              </div>
            </div>
          </div> */}
      {/* </>
      )}
      <button
        type="button"
        className="reviews__button"
        onClick={() => {
          setShowReview((prevState) => !prevState);
        }}
      >
        {showReview ? "Скрыть отзывы" : "Все отзывы"}
      </button> */}
    </>
  );
};
