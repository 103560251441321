import { useEffect } from "react";

export const Installed = () => {
  const param = new URLSearchParams(document.location.search);

  let source = param.get("source");
  let creative_id = param.get("creative_id");
  let ad_campaign_id = param.get("ad_campaign_id");
  let offer = param.get("offer");
  let creo_id = param.get("creo_id");
  let external_id = param.get("external_id");
  let site_id = param.get("site_id");
  let cost = param.get("cost");
  let sub_id_1 = param.get("sub_id_1");
  let sub_id_2 = param.get("sub_id_2");
  let sub_id_3 = param.get("sub_id_3");

  if (!cost) {
    cost = localStorage.getItem("cost");
  }

  if (!sub_id_1) {
    sub_id_1 = localStorage.getItem("sub_id_1");
  }

  if (!sub_id_2) {
    sub_id_2 = localStorage.getItem("sub_id_2");
  }

  if (!sub_id_3) {
    sub_id_3 = localStorage.getItem("sub_id_3");
  }

  if (!creo_id) {
    creo_id = localStorage.getItem("creo_id");
  }

  if (!external_id) {
    external_id = localStorage.getItem("external_id");
  }

  if (!site_id) {
    site_id = localStorage.getItem("site_id");
  }

  if (!source) {
    source = localStorage.getItem("source");
  }

  if (!creative_id) {
    creative_id = localStorage.getItem("creative_id");
  }

  if (!ad_campaign_id) {
    ad_campaign_id = localStorage.getItem("ad_campaign_id");
  }

  if (!offer) {
    offer = localStorage.getItem("offer");
  }

  let key = param.get("key");
  let fbclid = param.get("fbclid");
  let sub5 = param.get("sub5");
  let sub6 = param.get("sub6");
  let sub2 = param.get("sub2");
  let sub3 = param.get("sub3");

  if (!key) {
    key = localStorage.getItem("key");
  }

  if (!fbclid) {
    fbclid = localStorage.getItem("fbclid");
  }

  if (!sub5) {
    sub5 = localStorage.getItem("sub5");
  }

  if (!sub6) {
    sub6 = localStorage.getItem("sub6");
  }

  if (!sub2) {
    sub2 = localStorage.getItem("sub2");
  }

  if (!sub3) {
    sub3 = localStorage.getItem("sub3");
  }

  setTimeout(() => {
    window.location.href =
      `https://apitb13.com/cbm3l1k.php?a=b&sub3=app` +
      (key !== null ? `&key=${key}` : "") +
      (sub2 !== null ? `&sub2=${sub2}` : "") +
      (fbclid !== null ? `&sub4=${fbclid}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "");
  }, 800);

  setTimeout(() => {
    document.getElementById("openApp").click();
  }, 17400);

  useEffect(() => {
    localStorage.removeItem("isClickedInstall");
    const sendPixel = async () => {
      if (localStorage.getItem("pixelUsed")) {
        return;
      } else {
        await fetch(
          `https://naomc.top/api/postback/send?pixel=${sub6}&access_token=${sub5}&fbc=${fbclid}&status=lead`
        );
        localStorage.setItem("pixelUsed", true);
      }
    };

    sendPixel();
  }, []);

  return (
    <>
      <div class="loader"></div>
      {/*<div id="pixelTagId"></div> */}
    </>
  );
};
